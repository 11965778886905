import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';

import { AuthConfig, ChangePassword } from '@mp/auth/common/domain';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private readonly _apiBasePath: string = 'api/auth';

  constructor(
    private readonly oauthService: OAuthService,
    private readonly http: HttpClient,
  ) {}

  /**
   * Configures the login and executes it.
   * @param authConfig OAuth configuration.
   * @param state A state object that is preserved during the login process.
   */
  async setupAuthAndLogin<TState>(authConfig: AuthConfig, state: TState): Promise<TState> {
    this.setupAuth(authConfig);

    return await this.executeCodeFlowWithState(state);
  }

  getAccessToken(): string | null {
    if (this.oauthService.hasValidAccessToken()) {
      return this.oauthService.getAccessToken();
    }
    return null;
  }

  logout(noRedirectToLogoutUrl = false): void {
    this.oauthService.logOut(noRedirectToLogoutUrl);
  }

  changePassword(dto: ChangePassword): Observable<void> {
    return this.http.post<void>(`${this._apiBasePath}/changepassword`, dto);
  }

  private setupAuth(authConfig: AuthConfig): void {
    this.oauthService.configure(authConfig);
    this.oauthService.setupAutomaticSilentRefresh();
  }

  private async executeCodeFlowWithState<TState>(state: TState): Promise<TState> {
    await this.oauthService.loadDiscoveryDocument();
    await this.oauthService.tryLoginCodeFlow();

    // Wrap this, because the if branch should not fulfill. It redirects to the login page.
    return new Promise((resolve) => {
      if (!this.oauthService.hasValidAccessToken()) {
        // Note: This will actually **exit** the app if no token is present
        this.oauthService.initCodeFlow(state === undefined ? undefined : JSON.stringify(state));
        return;
      }

      resolve(this.oauthService.state ? (JSON.parse(decodeURIComponent(this.oauthService.state)) as TState) : state);
    });
  }
}
